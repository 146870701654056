import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo";
import Bio from "../components/bio";
import Layout from "../components/layout";
import Poster from "../components/poster";
import "../components/blog.scss";

type Props = {
  data: any;
  location: any;
};

function BlogIndex({ location, data }: Props) {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  const thumbnail = data.thumbnail.childImageSharp.fixed.src;

  return (
    <Layout location={location} title="Writing">
      <SEO title="Writing" thumbnail={thumbnail} />

      <Poster
        isFixed
        index={-1}
        node={{
          frontmatter: { poster: data.poster },
          fields: { slug: "" }
        }}
      />
      <div className="blog">
        <h1 className="blog-title">
          <span className="blog-text">Writing</span>
        </h1>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <div key={node.fields.slug} className="blog-item">
              <h3>
                <Link to={`writing/${node.fields.slug}`} className="blog-text">
                  {title}
                </Link>
                <div className="blog-date">
                  <span className="blog-text">{node.frontmatter.date}</span>
                </div>
              </h3>
              <div className="blog-body">
                <span
                  className="blog-text"
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt
                  }}
                ></span>
              </div>
            </div>
          );
        })}

        <div className="blog-item">
          <div className="blog-footer">
            <Bio />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    poster: file(relativePath: { eq: "sky.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thumbnail: file(relativePath: { eq: "emt_cover.jpg" }) {
      childImageSharp {
        fixed(width: 1024) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/writing/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
